import '../pcss/app.pcss'

import './components/lazyload'
import './components/carousel'

AOS.init({ disable: 'mobile' })

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
	import.meta.hot.accept(() => {
		console.log('HMR')
	})
}
