import Splide from '@splidejs/splide';
import $ from 'jquery'

if($('.splide').length > 0) {
    const splide = new Splide('.splide', {
        type: 'loop',
        perPage: 1,
        easing: 'linear',
        focus  : 'center',
        //rewind: true,
        pagination: true,
        arrows: false,
        lazyLoad: 'nearby',
        breakpoints: {
            768: {
                perPage: 1,
                padding: { left: '0', right: '0' },
                arrows: false,
                focus : 'left',
            },
            1160: {
                perPage: 1,
                focus : 'left',
                arrows: false
            },
            1368: {
                perPage: 1
            },
        }
    });

    splide.mount();
}